import React from 'react'

const Divider = () => {
  return (
    <div className="my-4 flex w-full items-center">
      <div className="my-2 h-0.5 grow border-t bg-gray-200 print:border-current print:bg-transparent"></div>
      <div className="my-2 h-0.5 grow border-t bg-gray-200 print:border-current print:bg-transparent"></div>
    </div>
  )
}

export default Divider
