'use client'
import { signIn, useSession } from 'next-auth/react'
import { useRouter, useSearchParams } from 'next/navigation'
import Link from 'next/link'
import Divider from '@/components/Divider'

export default function UserSignIn() {
  const searchParams = useSearchParams()
  const accountId = searchParams?.get('act')

  const router = useRouter()
  const { data } = useSession()

  const handlePassageSignIn = () => {
    signIn('passage')
  }

  if (data?.user) {
    router.push('/')
    return null
  }

  return (
    <div className="mt-6">
      <button
        onClick={handlePassageSignIn}
        className="w-full rounded-lg border-2 border-[#D02F97] bg-[#D02F97] px-4 py-2 text-[16px] font-medium text-white hover:bg-[#ba318a] focus:outline-none"
      >
        Employee Sign In
      </button>
      <Link className="" href="/signin?isAdmin=true">
        <button className="mt-4 w-full rounded-lg border-2 border-[#d02f98d4] px-4 py-2 text-[16px] font-semibold text-[#d02f98d4] hover:border-[#ba318a] hover:bg-[#ba318a] hover:text-white focus:outline-none">
          Admin Sign In
        </button>
      </Link>

      <Divider />
      <div className="flex justify-center">
        <div className="flex justify-center">
          <Link className="cursor-pointer text-blue-50 underline" href={`/signup?act=${accountId ?? ''}`}>
            Don&apos;t have an account? Sign Up
          </Link>
        </div>
      </div>
    </div>
  )
}
